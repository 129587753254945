import { html } from "htm/preact/index.js";
import { useEffect, useState } from "preact/hooks";
import { ArticlesList } from "./model";
import NewHeader from "./header";

export const PriceUpdateRequestForm = ({ id }: { id: string }) => {

    const item = ArticlesList.find((item) => item.id === id);

    const [formData, setFormData] = useState({
        eBuyNumber: 'eBuy-2024-0001',
        reference: id,
        customerArticleCode: item?.customer_article_number || '',
        name: '',
        email: '',
        quantity: '',
        machineStopped: false,
        attachment: null,
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        // In a real application, you'd send an email to satherm@satherm.com here
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return html`
            <${NewHeader} />
            <div className="container mt-3" >
                <h2 className="mb-2 text-black">Formulaire de demande d'actualisation de prix article</h2>
                <form onSubmit=${handleSubmit}>
                    <div className="mb-1">
                        <label className="form-label text-black">eBuy-Numero:</label>
                        <input type="text" className="form-control" value=${formData.eBuyNumber} disabled />
                    </div>

                    <div className="mb-1">
                        <label className="form-label text-black">Référence:</label>
                        <input type="text" className="form-control" name="reference" required value=${formData.reference} onChange=${handleInputChange} />
                    </div>

                    <div className="mb-1">
                        <label className="form-label text-black">Code article client:</label>
                        <input type="text" className="form-control" name="customerArticleCode" required value=${formData.customerArticleCode} onChange=${handleInputChange} placeholder="" />
                    </div>

                    <div className="mb-1">
                        <label className="form-label text-black">Nom:</label>
                        <input type="text" className="form-control" name="name" required value=${formData.name} onChange=${handleInputChange} requiblack />
                    </div>

                    <div className="mb-1">
                        <label className="form-label text-black">Email:</label>
                        <input type="email" className="form-control" name="email" required value=${formData.email} onChange=${handleInputChange} requiblack />
                    </div>

                    <div className="mb-1">
                        <label className="form-label text-black">Quantité:</label>
                        <select className="form-select" name="quantity" required value=${formData.quantity} onChange=${handleInputChange}>
                            <option value="">Sélectionnez une quantité</option>
                            ${[1, 5, 10, 20, 50, 100].map((q) => (
        html`
                                    <option key=${q} value=${q}>${q}</option>
                                `
    ))}
                        </select>
                    </div>

                    <div className="mb-1 form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="machineStopped"
                            name="machineStopped"
                            checked=${formData.machineStopped}
                            onChange=${handleInputChange}
                            
                        />
                        <label className="form-check-label text-black" for="machineStopped">Arrêt machine</label>
                    </div>

                    <div className="mb-1">
                        <label className="form-label text-black">Pièce jointe:</label>
                        <input type="file" className="form-control" name="attachment"  onChange=${handleInputChange} accept=".pdf,image/*" />
                    </div>

                    <div className="mb-1">
                        <label className="form-label text-black">Corps du message:</label>
                        <textarea
                            className="form-control"
                            name="message"
                            value=${formData.message}
                            onChange=${handleInputChange}
                            placeholder="Commentaire ou message complémentaire"
                            rows="3"
                            
                        ></textarea>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">Envoyer</button>
                    </div>
                </form>
            </div>
        `;
};

export const PriceOfferUpdateRequestForm = ({ id }: { id: string }) => {
    const [formData, setFormData] = useState({
        eBuyNumber: 'eBuy-2024-0001',
        offerNumber: id, // new field for N° Offre CSW
        name: '',
        email: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        // Simulate sending email to satherm@satherm.com
    };

    return html`
        <${NewHeader} />
        <div className="container mt-3" >
            <h2 className="mb-2 text-black">Formulaire de demande d'actualisation de prix offre</h2>
            <form onSubmit=${handleSubmit}>
                <!-- eBuy Number (non-editable) -->
                <div className="mb-1">
                    <label className="form-label text-black">eBuy-Nummer:</label>
                    <input type="text" className="form-control" value=${formData.eBuyNumber} disabled />
                    <small className="form-text text-muted">Champ généré automatiquement - non modifiable</small>
                </div>

                <!-- Offer Number -->
                <div className="mb-1">
                    <label className="form-label text-black">N° Offre CSW:</label>
                    <input type="text" className="form-control" name="offerNumber" value=${formData.offerNumber} onChange=${handleInputChange} placeholder="exemple 202305926" />
                </div>

                <!-- Name -->
                <div className="mb-1">
                    <label className="form-label text-black">Nom:</label>
                    <input type="text" className="form-control" name="name" value=${formData.name} onChange=${handleInputChange} required />
                </div>

                <!-- Email -->
                <div className="mb-1">
                    <label className="form-label text-black">Email:</label>
                    <input type="email" className="form-control" name="email" value=${formData.email} onChange=${handleInputChange} required />
                </div>

                <!-- Submit button -->
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary">Envoyer</button>
                </div>
            </form>
        </div>
    `;
};
