import { html } from 'htm/preact/index.js'
import { ctx } from '.'

const NewHeader = ({ withoutLogo = true, }: {withoutLogo : boolean } ) => {
    const { state } = ctx()
  return html`
    <nav class="header-navbar navbar-expand-lg navbar align-items-center navbar-brand-center " style="height: auto !important;">
        <div class="container-lg">
            <div class="d-flex align-items-center">
                ${
                    withoutLogo ? html`<a href="/">
                            <img src=${require("/assets/SathermLogo_CMYK.png")} alt="logo" 
                            style="width: 25rem;" 
                            class="py-2" />
                        </a>
                        <a href="/">
                            <img src=${require("/assets/Sanofi-logo.png")} alt="logo" 
                            style="width: 18rem;" 
                            class="" />
                        </a>` : null
                }
            </div>
            <div class="navbar-container d-flex content">
                <ul class="nav navbar-nav align-items-center ms-auto">
                        <ul class=" d-flex justify-content-center align-items-end " style="margin-right:30px">
                            <a class="nav-link  text-black fw-bold  custom-nav-link" aria-current="page" href="/">
                                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M280.4 148.3L96 300.1V464a16 16 0 0 0 16 16l112.1-.3a16 16 0 0 0 15.9-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.6a16 16 0 0 0 16 16.1L464 480a16 16 0 0 0 16-16V300L295.7 148.3a12.2 12.2 0 0 0 -15.3 0zM571.6 251.5L488 182.6V44.1a12 12 0 0 0 -12-12h-56a12 12 0 0 0 -12 12v72.6L318.5 43a48 48 0 0 0 -61 0L4.3 251.5a12 12 0 0 0 -1.6 16.9l25.5 31A12 12 0 0 0 45.2 301l235.2-193.7a12.2 12.2 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0 -1.7-16.9z"/></svg>
                            </a>
                                <a class="nav-link  text-black fw-bold  custom-nav-link" href="/offres">Offres</a>
                                <a class="nav-link  text-black fw-bold  custom-nav-link" href="/articles">Articles</a>
                                <a class="nav-link  text-black fw-bold  custom-nav-link" href="/contact">Contact</a>
                        </ul>
                    <li class="nav-item dropdown dropdown-cart me-25">
                        <a class="nav-link ${state.cartClass}" href="/cart"
                            data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-shopping-cart ficon">
                                <circle cx="9" cy="21" r="1"></circle>
                                <circle cx="20" cy="21" r="1"></circle>
                                <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                            </svg>
                            <span
                                class="badge rounded-pill bg-primary badge-up cart-item-count">${state.cart.items.reduce((p, n) => p + n.quantity, 0)}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  `
}

export default NewHeader
