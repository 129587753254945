import { html } from 'htm/preact';
import { useState } from 'preact/hooks';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        acceptPolicy: false,
        file: null,
    });

    console.log('====================================');
    console.log(formData.file);
    console.log('====================================');

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            file: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(formData);
    };

    return html`
    <form class="bg-white p-3 rounded" onSubmit=${handleSubmit}>
      <h4 class="text-black mb-2">Contact direct</h4>

      <!-- Name input -->
      <div class="form-group mb-1" style="display: flex; align-items: center; border: 1px solid #dcdcdc; padding: 5px; gap:4px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="15" style="margin-right: 8px;">
                <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7 .3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2 .4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/>
            </svg>
            <input
                type="text"
                class="rounded-1"
                id="name"
                name="name"
                value="${formData.name}"
                onInput="${handleInputChange}"
                placeholder="Nom *"
                required
                style="border: none; outline: none; flex: 1;"
            />
        </div>

      <!-- Email input -->
      <div class="form-group mb-1" style="display: flex; align-items: center; border: 1px solid #dcdcdc; padding: 5px;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="15" style="margin-right: 8px;">
                <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7 .3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2 .4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/>
            </svg>
            <input
                type="text"
                class="rounded-1"
                id="name"
                name="name"
                value="${formData.email}"
                onInput="${handleInputChange}"
                placeholder="Nom *"
                required
                style="border: none; outline: none; flex: 1;"
            />
        </div>

      <!-- Message input -->
       <div class="form-group mb-1" style="display: flex; align-items: start; border: 1px solid #dcdcdc; padding: 5px;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  width="15"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z"/></svg>
        <textarea
          class="rounded-1"
          id="message"
          name="message"
          rows="4"
          value=${formData.message}
          onInput=${handleInputChange}
          placeholder="Votre message *"
          required
          style="border: none; outline: none; width: 100%;"
        ></textarea>
      </div>

      <!-- File input -->
      <div class="form-group mb-1 d-flex justify-content-between align-items-center gap-2">
        <div>
            <label for="file" class="btn btn-danger text-white px-1">
                Importer une image
            </label>
            <input
            type="file"
            class="form-control-file"
            id="file"
            name="file"
            onChange=${handleFileChange}
            hidden
            />
        </div>
        <p class="d-flex gap-1 align-items-center mb-0">
            ${formData?.file?.name ?
            html`Detal : <p style="font-style: italic; font-weight: 800;" class="mb-0" >${formData?.file?.name}</p>` :
            html`<p class="mb-0">Types de fichiers: .jpg, .png, .zip, .gz, .tar</p>`
        }
        </p>
      </div>

      <!-- Privacy Policy checkbox -->
      <div class="form-check mb-1">
        <input
          type="checkbox"
          class="form-check-input"
          id="acceptPolicy"
          name="acceptPolicy"
          checked=${formData.acceptPolicy}
          onChange=${handleInputChange}
          required
        />
        <label class="form-check-label" for="acceptPolicy">
          J'ai lu et j'accepte la <a href="#">politique de confidentialité</a>
        </label>
      </div>

      <!-- Submit button -->
      <div class="d-flex justify-content-between align-items-center">
        <div>
            <button type="submit" class="btn btn-danger text-white">
                Envoyer
            </button>
        </div>
        <p class="mt-2">* Champs obligatoires</p>
      </div>
    </form>
  `;
};

export default ContactForm;
