import { html } from 'htm/preact/index.js';
import { ctx } from '.';


function convertDateFormat(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${month}.${day}.${year}`;
}
function revertDateFormat(dateString) {
  const [month, day, year] = dateString.split('.');
  return `${year}-${month}-${day}`;
}


const FilterComponent = () => {
    const {state , dispatch} = ctx()


  const handleInputChange = (e) => {
    let { name, value } = e.target;

    console.log("value ", typeof value, value.length);
    
    if (name == "request_date") {
      if (value.length !== 0) {
        value = convertDateFormat(value)
      }else{
        value = ""
      }
    }
    dispatch({
        type: 'filter.add',
        key: name,
        value: value ,  // Send null if no value to trigger removal
    });
  };

  return html`
  <div className="container mt-1">
    <div className="row">
      <div className="col-md-3 ps-0">
        <label htmlFor="date" className="form-label">Date</label>
        <input
          type="date"
          className="form-control"
          id="date"
          name="request_date"
          value=${revertDateFormat(state.filters.request_date) || ''}
          onChange=${handleInputChange}
        />
      </div>
      <div className="col-md-3">
        <label htmlFor="clientNumber" className="form-label">Numéro Client</label>
        <input
          type="text"
          className="form-control"
          id="clientNumber"
          name="customer_number"
          value=${state.filters.customer_number || ''}
          onChange=${handleInputChange}
          placeholder="Numéro Client"
        />
      </div>
      <div className="col-md-3">
        <label htmlFor="siteName" className="form-label">Nom du Site</label>
        <input
          type="text"
          className="form-control"
          id="siteName"
          name="customer_name"
          value=${state.filters.customer_name || ''}
          onChange=${handleInputChange}
          placeholder="Nom du Site"
        />
      </div>
      <div className="col-md-3 pe-0">
        <label htmlFor="requester" className="form-label">Demandeur</label>
        <input
          type="text"
          className="form-control"
          id="requester"
          name="contact"
          value=${state.filters.contact || ''}
          onChange=${handleInputChange}
          placeholder="Demandeur"
        />
      </div>
    </div>
  </div>
`;
};

export default FilterComponent;
