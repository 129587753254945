import { html } from "htm/preact/index.js";
import { route } from "preact-router";
import { useState } from "preact/hooks";

const Protection = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "satherm-admin" && password === "satherm-password") {
      window.localStorage.setItem("loggedin", true);
      route("/");
    } else {
      setError("Invalid credentials");
    }
  };

  return html`
    <div class="d-flex justify-content-center align-items-center" style="height: 100vh; background-color: #f0f2f5;">
      <div class="card shadow-lg p-4" style="width: 100%; max-width: 400px; border-radius: 10px;">
        <h2 class="text-center mb-2" style="color: #333;">
          Welcome Back
        </h2>
        <form onSubmit=${handleSubmit}>
          <div class="form-group mb-1">
            <label for="username" class="d-flex align-items-center" style="margin-bottom:4px; gap:4px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#0000002d" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/></svg>
              Username
            </label>
            <input
              type="text"
              class="form-control"
              id="username"
              value=${username}
              onInput=${(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              required
            />
          </div>
          <div class="form-group mb-2">
            <label for="password"  class="d-flex align-items-center" style="margin-bottom:4px; gap:4px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#0000002d" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"/></svg>
              Password
            </label>
            <input
              type="password"
              class="form-control"
              id="password"
              value=${password}
              onInput=${(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          ${Error && html`
            <p class="text-danger text-center mb-2 d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" width="16" height="16"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#d10107" d="M176 432c0 44.1-35.9 80-80 80s-80-35.9-80-80 35.9-80 80-80 80 35.9 80 80zM25.3 25.2l13.6 272C39.5 310 50 320 62.8 320h66.3c12.8 0 23.3-10 24-22.8l13.6-272C167.4 11.5 156.5 0 142.8 0H49.2C35.5 0 24.6 11.5 25.3 25.2z"/></svg>
              ${Error}
            </p>
          `}
          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-primary btn-block d-flex align-items-center gap-1 justify-content-center" style="background-color: #007bff; border-color: #007bff;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#fff" d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"/></svg>
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  `;
};

export default Protection;
