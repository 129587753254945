import { html } from 'htm/preact/index.js';
import { useEffect, useState } from 'preact/hooks';
import { ctx } from '.';
import ContactForm from './contact-form';
import Home from './home';
import contactImg from "./assets/img/IMG_9903b1licht2.jpg";
import contactUsImg from "./assets/img/5D3_4640 Kopie.jpg";

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    const { state, dispatch } = ctx();

    const [bgPosition, setBgPosition] = useState(0); // Initial background position y

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const parallaxSpeed = 0.5; // Adjust this value for slower or faster parallax effect
            const newBgPosition = -scrollY * parallaxSpeed; // Background moves at a slower rate than scrolling
            setBgPosition(newBgPosition); // Update state with the new background position
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return html`
    <${Home} imgUrl=${contactImg}  >
        <div class="pb-4">
            <div class="container py-3 pb-1">
                <h2 class="text-black fw-bold">Contactez-nous</h2>
            </div>
            <div class="container py-34 d-flex gap-4">
                <div class="bg-primary text-white px-3 py-3 w-50 pb-5">
                    <h3 class="text-white mb-2">Votre interlocuteur</h3>
                    <p class=" mb-2">Vous n'êtes qu'à un appel de votre réponse !</p>
                    <div class="d-flex gap-2">
                        <!-- <div class="pic-ansprech"></div> -->
                        <img  class="pic-ansprech" src=${contactUsImg} />
                        <div>
                            <p class="weiss" style="">
                                <strong>Team Satherm GmbH</strong>
                                <br></br>
                            </p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="weiss ">Téléphone :</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><a href="tel:+49683898080" class="text-white">+49 6838 9808-0</a></td>
                                    </tr>
                                    <tr>
                                        <td class="weiss ">Fax :</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="weiss" class="text-white">+49 6838 9808-88</td>
                                    </tr>
                                    <tr>
                                        <td class="weiss ">E-mail :</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><a href="mailto:satherm@satherm.com" class="text-white">satherm@satherm.com</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="w-50">
                    <${ContactForm} />
                </div>
            </div>
        </div>
    </${Home}>
  `;
}

export default Contact
